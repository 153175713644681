<template>
	<div class="w-screen h-screen fixed inset-0 border border-red-500 z-[90] pointer-events-none flex items-end">
		<div class="w-screen border border-blue-500 pointer-events-none bg-gray-100">
			<!-- Chat container !-->
			<div class="">
				<h3>Mensagens</h3>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ChatContainer"
}
</script>