<template>
    <div class="min-h-full flex overflow-hidden relative antialiased bg-gray-100" data-cy="app">

        <AppModal />

        <Sidebar/>

        <!-- Container !-->
        <div class="flex flex-col w-0 flex-1 overflow-hidden relative">
            <header class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
                <!-- MenuToogle !-->
                <button class="px-4 border-r border-gray-100 text-gray-500 focus:outline-none focus:bg-gray-200 focus:text-gray-600 hover:text-gray-600 md:hidden"
                        aria-label="Open sidebar">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7"/>
                    </svg>
                </button>
                <!-- HeaderBar !-->
                <div class="flex-1 px-4 flex justify-between">
                    <!-- SearchBar !-->
                    <SearchBar/>
                    <!-- UserMenu !-->
                    <UserMenu/>
                </div>
            </header>
            <!-- Content !-->
            <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
                <transition
                    enter-active-class="transition duration-300"
                    enter-class="opacity-0"
                    leave-active-class="transition duration-300"
                    leave-to-class="opacity-0"
                    mode="out-in">
                    <router-view class="py-6 sm:px-6"/>
                </transition>
            </main>
        </div>
        <!-- /Container !-->
        <!--        <LiveFeed />-->
        <!--        <Loading v-if="isLoading" overlay-opacity="100"/>-->
    </div>
</template>

<script>
import AppMixin from "@/layout/mixins/dashboard"
import Loading from "@/components/Loading"
import Sidebar from "@/layout/partner/Sidebar"
import SearchBar from "./SearchBar"
import UserMenu from "@/layout/partials/UserMenu"
import AppNotification from "@/layout/partials/AppNotification"
import AppModal from "@/layout/partials/AppModal"

import {get} from "vuex-pathify"

export default {
    name: "Dashboard",
    mixins: [AppMixin],
    components: {
        Loading,
        Sidebar,
        SearchBar,
        UserMenu,
        // AppEvents
        //
        // Slideover,
        // AppSlideover,
        // AppEvents,
        //
        // LiveFeed,
        // SearchBar,
        AppModal,
        AppNotification
    },
    data() {
        return {
            show: true
        }
    },
    computed: {},
    async mounted() {

        // if(this.notifications.length) {
        //   this.$notifyInfo(`Você possui ${this.notifications.length} notificações não lidas`, null, {
        //     size: "sm:max-w-md", timeout: 5000, link: '/notificacoes'
        //   });
        // }

        // setTimeout(() => {
        //
        //     // this.$notifyInfo("Você possui 30 notificações não lidas", null, { size: "sm:max-w-md", timeout: 15500 });
        //     // this.$notifyInfo("Você possui 21 notificações não lidas", null, { timeout: 15500 });
        //     // this.$notifySuccess("titulo", null, { timeout: 1000, sideAction: { label: "Teste", action: () => {} } });
        //
        //     // this.$slideover('Consultar Cliente', 'ConsultaClientePage', {
        //     //     onSelect(item){  console.log('cliente selecionado', item) }
        //     // })
        //
        //     this.$slideMensagens();
        //
        //     // this.$slideover('Mensagens', 'ListMensagemPage', {
        //     //     size: "sm:max-w-md",
        //     //     onSelect(item){  console.log('cliente selecionado', item) }
        //     // })
        //
        // }, 5000)

        // setTimeout(() => this.$modalInfo("teste", "teste"), 2000)

        // this.$modalError("Tteste", "teste", { iconPosition: 'left' });

        // setInterval(() => {
        //
        //     // this.$alertSuccess("Sucesso", { timeout: 15000 });
        //     // this.$notifySuccess("titulo", null, { timeout: 15500 });
        //     // this.$notifySuccess("titulo", null, { timeout: 1000, singleAction: { label: "Teste", action: () => {} } });
        //     // this.$notifySuccess("titulo", null, { timeout: 1000, sideAction: { label: "Teste", action: () => {} } });
        //
        // }, 8000);
    }
}
</script>
