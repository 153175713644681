<i18n src="@/i18n/backoffice/menu_links.json" />
<template>
	<nav class="py-5 text-gray-300 flex flex-col">
		<div class="px-1 mb-4 flex flex-col space-y-4">
			<div class="flex flex-col space-y-2">
				<!-- Welcome !-->
				<SidebarMenuItem :title="$t('menu_links.Dashboard')" icon="fa fa-home" :to="{ name: 'dashboard' }" />
				<!-- Shipments !-->
				<template v-if="canView('shipments')">
					<SidebarExpandMenuItem :title="$t('menu_links.Shipments')" icon="fa fa-dolly-flatbed-alt" open-on-path="/shipments" :to="{ name: 'shipments.index'}">
						<!-- All !-->
						<SidebarMenuItem :title="$t('menu_links.AllShipments')" icon="fa fa-bars" :to="{ name: 'shipments.index'}" active-on-exact />
						<!-- Medicines !-->
						<SidebarMenuItem :title="$t('menu_links.Medicines')" icon="fa fa-pills" :to="{ name: 'shipments.index', query: { category: 'medicines' }}" active-on-exact />
						<!-- Ecommerce !-->
						<SidebarMenuItem :title="$t('menu_links.Ecommerce')" icon="fa fa-box" :to="{ name: 'shipments.index', query: { category: 'ecommerce' }}" active-on-exact />
						<!-- Formal !-->
						<SidebarMenuItem :title="$t('menu_links.SpecialServices')" icon="fa fa-pallet-boxes" :to="{ name: 'shipments.index', query: { category: 'special_services' }}"
										 active-on-exact />
						<!-- Analyze !-->
						<SidebarMenuItem :title="$t('menu_links.Analyze')" icon="fa fa-magnifying-glass" :to="{ name: 'shipments.analyze' }" active-on-exact />
					</SidebarExpandMenuItem>
				</template>
				<!-- All !-->
				<!-- Manifests !-->
				<SidebarMenuItem :title="$t('menu_links.Manifests')" icon="fa fa-clipboard" :to="{ name: 'manifests.index' }" />
				<!--				<SidebarExpandMenuItem :title="$t('menu_links.Manifests')" icon="fa fa-dolly-flatbed-alt">-->
				<!--					&lt;!&ndash; All !&ndash;&gt;-->
				<!--					<SidebarMenuItem :title="$t('menu_links.All')" icon="fa fa-dolly-flatbed-alt" :to="{ name: 'shipments.index' }" />-->
				<!--					&lt;!&ndash; Shipments !&ndash;&gt;-->
				<!--					<SidebarMenuItem :title="$t('menu_links.ShipmentManifests')" icon="fa fa-dolly-flatbed-alt" :to="{ name: 'shipments.index' }" />-->
				<!--					&lt;!&ndash; Pre Alerts !&ndash;&gt;-->
				<!--					<SidebarMenuItem :title="$t('menu_links.PreAlertManifests')" icon="fa fa-dolly-flatbed-alt" :to="{ name: 'shipments.index' }" />-->
				<!--				</SidebarExpandMenuItem>-->
				<!-- / Manifests !-->
				<!-- Masters !-->
				<SidebarMenuItem v-if="canView('mawbs')" :title="$t('menu_links.Masters')" icon="fa fa-boxes-packing" :to="{ name: 'masters.index' }" />
				<!-- Orders !-->
				<SidebarMenuItem v-if="canView('orders')" :title="$t('menu_links.Orders')" icon="fa fa-file-lines" :to="{ name: 'orders.index' }" />
				<!-- Inventory !-->
				<SidebarMenuItem v-if="canView('inventories')" :title="$t('menu_links.Inventories')" icon="fa fa-box" :to="{ name: 'inventories.index' }" />
				<!-- Stocks !-->
				<SidebarMenuItem v-if="canView('stocks')" :title="$t('menu_links.Stock')" icon="fa fa-shelves" :to="{ name: 'stocks.index' }" />
				<!-- Imports !-->
				<SidebarMenuItem v-if="canView('imports')" :title="$t('menu_links.Imports')" icon="fa fa-cloud-upload-alt" :to="{ name: 'imports.index' }" />
				<!-- Reports !-->
				<template v-if="canView('reports')">
					<SidebarExpandMenuItem :title="$t('menu_links.Reports.title')" icon="fa fa-file-contract">
						<!-- Shipments !-->
						<SidebarMenuItem :title="$t('menu_links.Reports.Shipments')" icon="fa fa-dolly-flatbed" :to="{ name: 'reports.shipments' }" />
						<!-- Shipments !-->
						<SidebarMenuItem :title="$t('menu_links.Reports.Stocks')" icon="fa fa-dolly-flatbed" :to="{ name: 'reports.stocks' }" />
						<!-- Financial Shipments !-->
						<SidebarMenuItem :title="$t('menu_links.Reports.FinancialShipments')" icon="fa fa-university" :to="{ name: 'reports.financial_shipments' }" />
						<!-- Insurance Shipments !-->
						<SidebarMenuItem :title="$t('menu_links.Reports.InsuranceShipments')" icon="fa fa-university" :to="{ name: 'reports.insurance_shipments' }" />
						<!-- Detailed Shipments !-->
						<SidebarMenuItem :title="$t('menu_links.Reports.Detailed_Shipments')" icon="fa fa-dolly-flatbed" :to="{ name: 'reports.detailed_shipments' }" />
						<!-- Custom LACC !-->
						<!--						<SidebarMenuItem v-if="canView('users')" :title="$t('menu_links.Reports.Reports.Shipments-LACC')" icon="fa fa-dolly-flatbed" :to="{ name: 'reports.shipments_lacc' }" />-->
					</SidebarExpandMenuItem>
				</template>
				<!-- Configurations !-->
				<template v-if="canViewConfig">
					<SidebarExpandMenuItem :title="$t('menu_links.Configurations')" icon="fa fa-gears">
						<!-- Subsidiaries !-->
						<SidebarMenuItem v-if="canView('subsidiaries')" :title="$t('menu_links.Subsidiary')" icon="fa fa-building" :to="{ name: 'subsidiaries.index' }" />
						<!-- Services !-->
						<SidebarMenuItem v-if="canView('services')" :title="$t('menu_links.Services')" icon="fa fa-diagram-project" :to="{ name: 'services.index' }" />
						<!-- ServiceGroups !-->
						<SidebarMenuItem v-if="canView('service_groups')" :title="$t('menu_links.ServiceGroups')" icon="fa fa-layer-group" :to="{ name: 'service_groups.index' }" />
						<!-- Partners !-->
						<SidebarMenuItem v-if="canView('partners')" :title="$t('menu_links.Partners')" icon="fa fa-user-tie" :to="{ name: 'partners.index' }" />
						<!-- Providers !-->
						<SidebarMenuItem v-if="canView('providers')" :title="$t('menu_links.Providers')" icon="fa fa-user-hard-hat" :to="{ name: 'providers.index' }" />
						<!-- Clients !-->
						<SidebarMenuItem v-if="canView('clients')" :title="$t('menu_links.Clients')" icon="fa fa-book-user" :to="{ name: 'clients.index' }" />
						<!-- Warehouses !-->
						<SidebarMenuItem v-if="canView('warehouses')" :title="$t('menu_links.Warehouses')" icon="fa fa-warehouse" :to="{ name: 'warehouses.index' }" />
						<!-- Integrations !-->
						<SidebarMenuItem v-if="canView('integrations')" :title="$t('menu_links.Integrations')" icon="fa fa-exchange-alt" :to="{ name: 'integrations.index' }" />
						<!-- Auxiliar !-->
						<template v-if="canViewCommon">
							<SidebarExpandMenuItem :title="$t('menu_links.Auxiliar')" icon="fa fa-caret-down">
								<!-- Countries !-->
								<SidebarMenuItem v-if="canView('countries')" :title="$t('menu_links.Countries')" icon="fa fa-earth-americas" :to="{ name: 'countries.index' }" />
								<!-- Locales !-->
								<SidebarMenuItem v-if="canView('languages')" :title="$t('menu_links.Languages')" icon="fa fa-language" :to="{ name: 'languages.index' }" />
								<!-- Locales !-->
								<SidebarMenuItem v-if="canView('airports')" :title="$t('menu_links.Airports')" icon="fa fa-plane-departure" :to="{ name: 'airports.index' }" />
								<!-- Currency !-->
								<SidebarMenuItem v-if="canView('currencies')" :title="$t('menu_links.Currencies')" icon="fa fa-dollar-sign" :to="{ name: 'currencies.index' }" />
								<!-- Inventories !-->
								<SidebarMenuItem v-if="canView('inventories')" :title="$t('menu_links.Inventories')" icon="fa fa-boxes" :to="{ name: 'inventories.index' }" />
								<!-- Packages !-->
								<SidebarMenuItem v-if="canView('packages')" :title="$t('menu_links.Packages')" icon="fa fa-box" :to="{ name: 'packages.index' }" />
								<!-- Shipment TrackingEvents !-->
								<SidebarMenuItem v-if="canView('events')" :title="$t('menu_links.TrackingEvents')" icon="fa fa-location-pin" :to="{ name: 'tracking_events.index' }" />
								<!-- Banks !-->
								<SidebarMenuItem v-if="canView('banks')" :title="$t('menu_links.Banks')" icon="fa fa-university" :to="{ name: 'banks.index' }" />
								<!-- Documents !-->
								<SidebarMenuItem v-if="canView('documents')" :title="$t('menu_links.Documents')" icon="fa fa-id-card" :to="{ name: 'documents.index' }" />
								<!-- Departments !-->
								<SidebarMenuItem v-if="canView('departments')" :title="$t('menu_links.Departments')" icon="fa fa-users-line" :to="{ name: 'departments.index' }" />
								<!-- Support Subject !-->
								<SidebarMenuItem v-if="canView('subjects')" :title="$t('menu_links.SupportSubjects')" icon="fa fa-headset" :to="{ name: 'support-subjects.index' }" />
							</SidebarExpandMenuItem>
						</template>
						<!-- / Common !-->
					</SidebarExpandMenuItem>
				</template>
				<!-- / Configurations !-->
				<!-- Financial !-->
				<template v-if="canView('financial')">
					<SidebarExpandMenuItem :title="$t('menu_links.Financial')" icon="fa fa-file-contract">
						<!-- Invoices !-->
						<SidebarMenuItem v-if="canView('invoices')" :title="$t('menu_links.Invoices')" icon="fa fa-file" :to="{ name: 'invoices.index' }" />
						<!-- PriceTables !-->
						<SidebarMenuItem v-if="canView('price_tables')" :title="$t('menu_links.PriceTables')" icon="fa fa-table" :to="{ name: 'price_tables.index' }" />
						<!-- TaxTables !-->
						<SidebarMenuItem v-if="canView('tax_tables')" :title="$t('menu_links.TaxTables')" icon="fa fa-cloud-upload-alt" :to="{ name: 'tax_tables.index' }" />
					</SidebarExpandMenuItem>
				</template>
				<!-- Tools !-->
				<template v-if="canView('tools')">
					<SidebarExpandMenuItem :title="$t('menu_links.Tools')" icon="fa fa-tools">
						<!-- Event !-->
						<SidebarMenuItem v-if="canView('tools_book_events')" :title="$t('menu_links.BulkEvents')" icon="fa fa-location-dot" :to="{ name: 'tools.bulk_events' }" />
						<!-- Tracking !-->
						<SidebarMenuItem v-if="canView('tools_tracking')" :title="$t('menu_links.Tracking')" icon="fa fa-search" :to="{ name: 'tools.tracking' }" />
						<!-- Tracking !-->
						<SidebarMenuItem v-if="canView('tools_pre_alert_lastmile')" :title="$t('menu_links.PreAlertLastMile')" icon="fa fa-search"
										 :to="{ name: 'tools.pre_alert_lastmile' }" />
						<!-- RateDiscrepancy !-->
						<SidebarMenuItem v-if="canView('rate_discrepancy_report')" :title="$t('menu_links.RateDiscrepancyReport')" icon="fa fa-search"
										 :to="{ name: 'tools.rate_discrepancy_report' }" />
						<!-- Mawb Event Check Report !-->
						<SidebarMenuItem v-if="canView('mawb_event_check_report')" :title="$t('menu_links.MawbEventCheckReport')" icon="fa fa-search"
										 :to="{ name: 'tools.mawb_event_check_report' }" />
					</SidebarExpandMenuItem>
				</template>
				<!-- Users !-->
				<SidebarMenuItem v-if="canView('users')" :title="$t('menu_links.Users')" icon="fa fa-users" :to="{ name: 'users.index' }" />
			</div>
			<hr class="opacity-25">
			<!-- Solicita !-->
			<SidebarMenuItem v-if="canView('bots')" :title="$t('menu_links.Bot_Solicita')" icon="fa fa-robot" :to="{ name: 'anvisa_solicita.index' }" />
			<hr class="opacity-25">
			<div class="flex flex-col space-y-2">
				<!-- Processes !-->
				<SidebarMenuItem :title="$t('menu_links.Processes')" icon="fa fa-gears" :to="{ name: 'processes' }" />
				<!-- Notifications !-->
				<SidebarMenuItem :title="$t('menu_links.Notifications')" icon="fa fa-bell" :to="{ name: 'notifications' }" />
				<!-- My Account !-->
				<SidebarMenuItem :title="$t('menu_links.My Account')" icon="fa fa-user" :to="{ name: 'my-account' }" />
			</div>
		</div>
		<hr class="opacity-25 my-4">
		<!-- FAQ !-->
		<!--		<SidebarMenuItem :title="$t('menu_links.FAQ')" icon="fa fa-question-circle" :to="{ name: 'faq' }" />-->
		<!-- Support !-->
		<SidebarMenuItem :title="$t('menu_links.Support')" icon="fa fa-user-headset" :to="{ name: 'supports.index' }" />
	</nav>
</template>

<script>
import { get } from "vuex-pathify"

import OpacityScaleTransition from "@/components/OpacityScaleTransition"
import SidebarMenuItem from "@/components/SidebarMenuItem.vue"
import SidebarExpandMenuItem from "@/components/SidebarExpandMenuItem.vue"

export default {
	name: "MenuLinks",
	components: { SidebarExpandMenuItem, SidebarMenuItem, OpacityScaleTransition },
	props: {
		showLabels: {
			type: [Boolean, Object],
			default: true
		}
	},
	data() {
		return {
			showConfig: false,
			showAuxiliar: false,
			showManifest: false,
			showReports: false
		}
	},
	computed: {
		profile: get("APP/profile"),
		hasNewNotifications: get("Notifications/hasNewNotifications"),
		canViewCommon() {
			const commonEntities = [
				"countries.view",
				"currencies.view",
				"languages.view",
				"airports.view",
				"banks.view",
				"documents.view",
				"departments.view",
				"packages.view",
				"subjects.view",
				"events.view"
			]
			return commonEntities.some(entity => this.canView(entity))
		},
		canViewConfig() {
			return true
		},
		canViewReports() {
			const commonEntities = [
				"reports.view"
			]
			return commonEntities.some(entity => this.canView(entity))
		}
	},
	methods: {
		/**
		 * Check if the user can view the entity
		 * @param entity
		 */
		canView(entity) {
			if (!this.profile.permissions?.length) {
				return true
			}

			return this.profile.permissions.includes(entity + ".view")
		}
	}
}
</script>
