<i18n src="@/i18n/user_menu.json"></i18n>
<template>
	<div class="ml-4 flex items-center md:ml-6 space-x-8">
		<!--		&lt;!&ndash; My Account !&ndash;&gt;-->
		<!--		<button-->
		<!--			class="p-1 flex items-center text-gray-400 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"-->
		<!--			aria-label="Notifications"-->
		<!--			@click="handleMyAccount">-->
		<!--			<i class="fal fa-user"></i>-->
		<!--			<span class="text-sm ml-2">{{$t('user_menu.my_account')}}</span>-->
		<!--		</button>-->
		<!-- Process !-->
<!--		<button-->
<!--			class="ml-2 p-1 flex items-center relative hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"-->
<!--			:class="{ 'text-gray-500' : hasNewProcesses, 'text-gray-400': !hasNewProcesses }"-->
<!--			aria-label="Notifications"-->
<!--			@click="handleProcess">-->
<!--			<i v-if="hasNewProcesses" class="fa fa-circle text-red-500 absolute top-0 right-0 -mr-1.5" style="font-size: 6px"></i>-->
<!--			<i class="fa-gears" :class="{ 'fa text-yellow-400' : hasNewProcesses, 'fal' : !hasNewProcesses  }"></i>-->
<!--			<span class="text-sm ml-2">{{ $t('user_menu.process') }}</span>-->
<!--		</button>-->
		<button
			class="ml-2 p-1 flex items-center relative hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
			:class="{ 'text-gray-500' : hasNewProcesses, 'text-gray-400': !hasNewProcesses }"
			aria-label="Notifications"
			@click="handleMessenger">
			<i v-if="hasNewProcesses" class="fa fa-circle text-red-500 absolute top-0 right-0 -mr-1.5" style="font-size: 6px"></i>
			<i class="fa-comments" :class="{ 'fa text-yellow-400' : hasNewProcesses, 'fal' : !hasNewProcesses  }"></i>
			<span class="text-sm ml-2">{{ $t('user_menu.messages') }}</span>
		</button>
		<!-- / Process !-->
		<!-- Notifications !-->
		<button
			class="ml-2 p-1 flex items-center relative  hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
			:class="{ 'text-gray-500' : hasNewNotifications, 'text-gray-400': !hasNewNotifications }"
			aria-label="Notifications"
			@click="showNotificationSlider = true">
			<i v-if="hasNewNotifications" class="fa fa-circle text-red-500 absolute top-0 right-0 -mr-1.5" style="font-size: 6px"></i>
			<i class="fa-bell" :class="{ 'fa text-yellow-400' : hasNewNotifications, 'fal' : !hasNewNotifications  }"></i>
			<span class="text-sm ml-2">{{ $t('user_menu.notifications') }}</span>
		</button>
		<!-- / Notifications !-->
		<div class="ml-6 relative">
			<div>
				<button @click="handleToggle" class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline" id="user-menu"
						aria-label="User menu" aria-haspopup="true" v-bind:aria-expanded="show">
					<ProfileImage :src="profile.picture_url" :letters="profile.name" no-shadow/>
				</button>
			</div>
			<transition
				enter-class="transform opacity-0 scale-95"
				enter-active-class="transition ease-out duration-100"
				enter-to-class="transform opacity-100 scale-100"
				leave-class="transform opacity-100 scale-100"
				leave-to-class="transform opacity-0 scale-95"
				leave-active-class="transition ease-in duration-75"
			>
				<!-- Drop !-->
				<div ref="drop" v-show="show" class="z-[100] drop origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
					<div class="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
						<!-- My Account !-->
						<button @click="handleMyAccount"
								class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 cursor-pointer transition ease-in-out duration-150"
								role="menuitem">{{ $t('user_menu.my_account') }}
						</button>
						<!-- User Switch !-->
						<template v-if="profile.user_switch">
							<button class="w-full block px-4 py-2 text-left text-sm text-yellow-500 hover:bg-gray-100 cursor-pointer transition ease-in-out duration-150" role="menuitem"
									@click="handleSwitchBack">
								<i class="fa fa-arrow-rotate-left mr-1"></i>
								{{ $t('user_menu.switch_back') }}
							</button>
						</template>
						<!-- Logout !-->
						<button class="w-full block px-4 py-2 text-left text-sm text-red-500 hover:bg-gray-100 cursor-pointer transition ease-in-out duration-150" role="menuitem"
								@click="handleLogout">
							<i class="fa fa-lock mr-1"></i>
							{{ $t('user_menu.logout') }}
						</button>
					</div>
				</div>
				<!-- / Drop !-->
			</transition>
		</div>
		<!-- Notification Slider !-->
		<PageDrawer v-model="showNotificationSlider">
			<NotificationSlider @close="showNotificationSlider = false"/>
		</PageDrawer>
	</div>
</template>

<script>
import {get, call, sync} from "vuex-pathify"
import User from "@/API/User.js"

import ProfileImage from "@/components/ProfileImage.vue"
import SlideoverMixin from "@/mixins/slideover.js"
import PageDrawer from "@/components/PageDrawer.vue"
import NotificationSlider from "@/pages/shared/notifications/slides/NotificationSlider.vue"

let openedWindow = null;

export default {
	name: "UserMenu",
	mixins: [SlideoverMixin],
	components: {NotificationSlider, PageDrawer, ProfileImage},
	data() {
		return {
			show: false,
			showNotificationSlider: false
		}
	},
	computed: {
		profile: get('APP/profile'),
		showMessenger: sync('APP/showMessenger'),
		hasNewNotifications: get('Notifications/hasNewNotifications'),
		hasNewProcesses: get('Chat/hasNewMessages'),
		hasNewMessages: get('Notifications/hasNewNotifications'),
	},
	methods: {
		logout: call('APP/logout'),
		handleMessenger(){
			this.showMessenger = !this.showMessenger
		},
		/**
		 * Handler toggle
		 */
		handleToggle() {
			this.show = !this.show
		},
		/**
		 * Handler my account
		 */
		handleMyAccount() {
			this.show = false
			this.$router.push({name: 'my-account'}).catch(() => {
			})
		},
		/**
		 * Handler process
		 */
		handleProcess() {
			this.$router.push({name: 'processes'}).catch(() => {
			})
		},
		/**
		 * Handler notifications
		 */
		handleNotifications() {
			this.$router.push({name: 'notifications'}).catch(() => {
			})
		},
		/**
		 * Handler switch back
		 */
		async handleSwitchBack() {
			await User.switchBack()
			window.location.reload()
		},
		/**
		 * Handler logout
		 */
		handleLogout() {
			this.show = false
			this.logout()
		}
	},
	watch: {
		show(value) {
			if (value) {
				setTimeout(() => document.addEventListener('click', this.away, false), 1)
			} else {
				document.removeEventListener('click', (event) => {
					const closest = event.target.closest('.drop')
					if (!closest && closest != this.$refs.drop) this.show = false
				}, false)
			}
		}
	}

}
</script>
