import Dashboard from "@/API/Dashboard"

import LoadingMixin from "@/mixins/loading"
import SystemMixin from "@/mixins/system"

export default {
    mixins: [LoadingMixin, SystemMixin],
    data(){
        return {
            page: {
                totals: {}
            }
        }
    },
    methods: {
        async load(background = false){
            console.debug('Dashboard@load carregando dados', { background })

            background ? this.bgLoading() : this.loading()

            const [err, res] = await Dashboard.load()

            this.loaded()

            if (err) {
                console.error('Dashboard@load erro ao carregar dados', err)
                this.$alertError("Ops, houve um erro ao carregar os dados, tente novamente.")
                return false
            }

            this.page.totals = res.data

            return true
        },
        async init(){
            await this.load()
        }
    }
}