<template>
    <div class="flex relative antialiased bg-gray-100" data-cy="app">

		<PortalTarget name="tooltip" />

        <AppModal/>
<!--		<ChatContainer />-->
        <!--    <AppNotification />-->
        <!--    <AppSlideover />-->
        <!--    <AppEvents />-->

        <Sidebar />

        <!-- Container !-->
        <div class="flex flex-col w-0 flex-1 relative">
            <header class="relative flex-shrink-0 flex h-16 bg-white shadow">
                <!-- MenuToggle !-->
                <button
                    class="px-4 border-r border-gray-100 text-gray-500 focus:outline-none focus:bg-gray-200 focus:text-gray-600 hover:text-gray-600 md:hidden"
                    aria-label="Open sidebar"
                    @click="sidebarOpened = true">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7"/>
                    </svg>
                </button>
                <!-- HeaderBar !-->
                <div class="flex-1 px-4 flex justify-between">
                    <!-- SearchBar !-->
                    <SearchBar/>
                    <!-- UserMenu !-->
                    <UserMenu/>
                </div>
            </header>
            <!-- Content !-->
            <main class="flex-1 flex flex-col h-full relative focus:outline-none" tabindex="0">
                <transition
                    enter-active-class="transition duration-300"
                    enter-class="opacity-0"
                    leave-active-class="transition duration-100"
                    leave-to-class="opacity-0"
                    mode="out-in">
					<router-view class="sm:p-3 2xl:sm:p-6 flex-1"/>
                </transition>
                <footer class="h-6 -mt-6 relative z-10 flex-shrink-0 flex  bg-white border-t border-gray-200 items-center justify-end px-4">by Tristar Express</footer>
            </main>
        </div>
        <!-- /Container !-->
        <!--        <LiveFeed />-->
        <!--        <Loading v-if="isLoading" overlay-opacity="100"/>-->
    </div>
</template>

<script>
import Mixin from "./mixins/dashboard.js"
import Loading from "@/components/Loading.vue"
import Sidebar from "@/layout/backoffice/Sidebar.vue"
import SearchBar from "./SearchBar.vue"
import UserMenu from "@/layout/partials/UserMenu.vue"
import AppNotification from "@/layout/partials/AppNotification.vue"
import AppModal from "@/layout/partials/AppModal.vue"
import ChatContainer from "@/pages/shared/chat/ChatContainer.vue";

// import AppSlideover from "@/pages/partials/AppSlideover"
// import LiveFeed from "@/pages/partials/LiveFeed"
// import AppEvents from "@/pages/partials/AppEvents"
// import Slideover from "../components/Slideover"

export default {
    name: "Dashboard",
    mixins: [Mixin],
    components: {
		ChatContainer,
        Loading,
        Sidebar,
        SearchBar,
        UserMenu,
        AppModal,
        AppNotification,
		// AppEvents
		// Slideover,
		// AppSlideover,
		// AppEvents,
		// LiveFeed,
		// SearchBar,
    },
    data() {
        return {
            show: true
        }
    }

}
</script>
