import VueRouter from "vue-router"

import LoginPage from "@/pages/backoffice/auth/LoginPage"

const routes = [
	{
		path: "/",
		component: LoginPage
	},
	{
		path: "/login",
		component: LoginPage
	}
]

export default new VueRouter({
	mode: process.env.NODE_ENV === "production" ? "history" : "hash",
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})
