<i18n src="@/i18n/backoffice/dashboard/totals_partial.json"></i18n>
<template>
	<div>
		<!-- Title !-->
		<h3 class="text-lg leading-6 font-medium text-gray-900 pl-4 sm:pl-0">
			{{ $t("dashboard.totals_partial.title") }}
		</h3>
		<!-- Blocks !-->
		<dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
			<!-- Total Shipments !-->
			<div class="px-4 py-5 bg-white shadow sm:rounded-lg overflow-hidden sm:p-6">
				<dt class="text-sm font-medium text-gray-500 truncate">
					{{ $t("dashboard.totals_partial.total_shipments") }}
				</dt>
				<dd class="mt-1">
					<span class="text-3xl font-semibold text-gray-900">{{ dashboard.totals.total_shipments }}</span>
				</dd>
			</div>
			<!-- Total Clients !-->
			<div class="px-4 py-5 bg-white shadow sm:rounded-lg overflow-hidden sm:p-6">
				<dt class="text-sm font-medium text-gray-500 truncate">
					{{ $t("dashboard.totals_partial.total_clients") }}
				</dt>
				<dd class="mt-1">
					<span class="text-3xl font-semibold text-gray-900">{{ dashboard.totals.total_clients }}</span>
				</dd>
			</div>
			<!-- Total Tickets !-->
			<div class="px-4 py-5 bg-white shadow sm:rounded-lg overflow-hidden sm:p-6">
				<dt class="text-sm font-medium text-gray-500 truncate">
					{{ $t("dashboard.totals_partial.total_tickets") }}
				</dt>
				<dd class="mt-1">
					<span class="text-3xl font-semibold text-gray-900">{{ dashboard.totals.total_tickets }}</span>
				</dd>
			</div>
		</dl>
		<!-- /Blocks !-->
	</div>
</template>

<script>
export default {
	name: "TotalsPartial",
	inject: ['dashboard']
}
</script>
