<i18n src="@/i18n/search_bar.json"></i18n>
<template>
    <!-- Search !-->
    <div class="flex-1 flex">
        <div class="drop w-full flex md:ml-0 md:visible">
            <label for="search_field" class="sr-only">{{ $t('search_bar.placeholder') }}</label>
            <div class="relative w-full py-2 text-gray-400 focus-within:text-gray-600">
                <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none">
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"/>
                    </svg>
                </div>
                <input id="search_field"
                       class="form-input block w-full h-full pl-8 pr-3 py-4 border-0 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm"
                       :placeholder="$t('search_bar.placeholder')" type="search" v-model="search" @focus="showBar = true" />

                <div v-if="showBar" ref="drop" class="origin-top-right absolute w-full mt-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu"
                     aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="px-6 py-4">
                        <h3>{{ $t('search_bar.menu_itens') }}</h3>
                        <div class="flex flex-wrap mt-5">
                            <template v-for="(item, index) in filteredSearchItens">
                                <button class="mr-3 mb-4 flex-shrink-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-md font-medium bg-gray-100 text-gray-800" @click="handleClick(item)">
                                    {{ $t(item.title) }}
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Search !-->
</template>

<script>

import Fuse from 'fuse.js'

export default {
    name: "SearchBar",
    data() {
        return {
            showBar: false,
            search: "",
            searchItens: [
                {"title": "Início", "route": { name: 'dashboard' }},
                {"title": "Calculadora", "route": { name: 'calculator' }},
                {"title": "Remessa", "route": { name: 'shipment' }},
                {"title": "Notificar encomenda", "route": { name: 'notify-shipment' }},
                {"title": "Meus endereços", "route": { name: 'addresses' }},
                {"title": "Pontos de atendimento", "route": { name: 'advanced-posts' }},
                {"title": "Relatórios", "route": { name: 'reports' }},
                {"title": "Indicar", "route": { name: 'indicate' }},
                {"title": "Notificações", "route": { name: 'notifications' }},
                {"title": "Minha conta", "route": { name: 'my-account' }},
                {"title": "FAQ", "route": { name: 'faq' }},
                {"title": "Suporte", "route": { name: 'supports' }},
            ]
        }
    },
    computed: {
        filteredSearchItens() {
            if (!this.search?.length) return this.searchItens

            const fuse = new Fuse(this.searchItens, {
                keys: ['title']
            })

            return fuse.search(this.search).map(result => result.item);
        }
    },
    methods: {
        handleToogle(){
            this.showBar = !this.showBar
        },
        away(event) {
            const closest = event.target.closest('.drop')
            if (!closest && closest != this.$refs.drop) this.showBar = false
        },
        handleClick(item){
            this.showBar = false
            this.$router.push(item.route)
        }
    },
    watch: {
        showBar(value) {
            if (value) {
                setTimeout(() => document.addEventListener('click', this.away, false), 1)
            } else {
                document.removeEventListener('click', this.away, false)
            }
        }
    }
}
</script>
