import Vue from "vue"
import VueRouter from "vue-router"
import { dispatch } from "vuex-pathify"
import NProgress from "nprogress"
import store from "@/store.js"

Vue.use(VueRouter)

/**
 * Dashboard
 * ------------------------------------------------------------------------------------------------ */
import Dashboard from "@/layout/backoffice/Dashboard"
import DashboardPage from "@/pages/backoffice/dashboard/DashboardPage"

/**
 * Shipment
 * ------------------------------------------------------------------------------------------------ */
const ShipmentIndexPage = () => import(/* webpackChunkName: "bo-group-shipment" */"@/pages/backoffice/shipments/IndexPage.vue")
const ShipmentFormPage = () => import(/* webpackChunkName: "bo-group-shipment" */"@/pages/backoffice/shipments/FormPage.vue")
const ShipmentFormCTEPage = () => import(/* webpackChunkName: "bo-group-shipment" */"@/pages/backoffice/shipments/FormCTEPage.vue")
const ShipmentShowPage = () => import(/* webpackChunkName: "bo-group-shipment" */"@/pages/backoffice/shipments/ShowPage.vue")
const ShipmentAnalyzePage = () => import(/* webpackChunkName: "bo-group-shipment" */"@/pages/backoffice/shipments/AnalyzePage.vue")

/**
 * Services
 * ------------------------------------------------------------------------------------------------ */
const ServiceIndexPage = () => import(/* webpackChunkName: "bo-group-service" */"@/pages/backoffice/services/IndexPage.vue")
const ServiceFormPage = () => import(/* webpackChunkName: "bo-group-service" */"@/pages/backoffice/services/FormPage.vue")

/**
 * Service Groups
 * ------------------------------------------------------------------------------------------------ */
const ServiceGroupIndexPage = () => import(/* webpackChunkName: "bo-group-service" */"@/pages/backoffice/service_groups/IndexPage.vue")
const ServiceGroupFormPage = () => import(/* webpackChunkName: "bo-group-service" */"@/pages/backoffice/service_groups/FormPage.vue")

/**
 * Subsidiary
 * ------------------------------------------------------------------------------------------------ */
const SubsidiariesIndexPage = () => import(/* webpackChunkName: "bo-group-subsidiary" */"@/pages/backoffice/subsidiaries/IndexPage.vue")
const SubsidiariesFormPage = () => import(/* webpackChunkName: "bo-group-subsidiary" */"@/pages/backoffice/subsidiaries/FormPage.vue")

/**
 * Partner
 * ------------------------------------------------------------------------------------------------ */
const PartnerIndexPage = () => import(/* webpackChunkName: "bo-group-partner" */"@/pages/backoffice/partner/IndexPage.vue")
const PartnerFormPage = () => import(/* webpackChunkName: "bo-group-partner" */"@/pages/backoffice/partner/FormPage.vue")

/**
 * Client
 * ------------------------------------------------------------------------------------------------ */
const ClientIndexPage = () => import(/* webpackChunkName: "bo-group-client" */"@/pages/backoffice/client/IndexPage.vue")
const ClientFormPage = () => import(/* webpackChunkName: "bo-group-client" */"@/pages/backoffice/client/FormPage.vue")

/**
 * Provider
 * ------------------------------------------------------------------------------------------------ */
const ProviderIndexPage = () => import(/* webpackChunkName: "bo-group-provider" */"@/pages/backoffice/provider/IndexPage.vue")
const ProviderFormPage = () => import(/* webpackChunkName: "bo-group-provider" */"@/pages/backoffice/provider/FormPage.vue")

/**
 * Airport
 * ------------------------------------------------------------------------------------------------ */
const AirportIndexPage = () => import(/* webpackChunkName: "bo-group-airport" */"@/pages/backoffice/airports/IndexPage.vue")
const AirportFormPage = () => import(/* webpackChunkName: "bo-group-airport" */"@/pages/backoffice/airports/FormPage.vue")

/**
 * Review
 * ------------------------------------------------------------------------------------------------ */
const ReviewIndexPage = () => import(/* webpackChunkName: "bo-group-review" */"@/pages/backoffice/reviews/IndexPage.vue")
const ReviewFormPage = () => import(/* webpackChunkName: "bo-group-review" */"@/pages/backoffice/reviews/FormPage.vue")

/**
 * Document
 * ------------------------------------------------------------------------------------------------ */
const DocumentIndexPage = () => import(/* webpackChunkName: "bo-group-document" */"@/pages/backoffice/documents/IndexPage.vue")
const DocumentShowPage = () => import(/* webpackChunkName: "bo-group-document" */"@/pages/backoffice/documents/ShowPage.vue")
const DocumentFormPage = () => import(/* webpackChunkName: "bo-group-document" */"@/pages/backoffice/documents/FormPage.vue")


/**
 * Warehouse
 * ------------------------------------------------------------------------------------------------ */
const WarehouseIndexPage = () => import(/* webpackChunkName: "bo-group-warehouse" */"@/pages/backoffice/warehouse/IndexPage.vue")
const WarehouseFormPage = () => import(/* webpackChunkName: "bo-group-warehouse" */"@/pages/backoffice/warehouse/FormPage.vue")

const ImportSelectPage = () => import(/* webpackChunkName: "bo-group-import" */"@/pages/backoffice/imports/SelectPage.vue")
const ImportFormPage = () => import(/* webpackChunkName: "bo-group-import" */"@/pages/backoffice/imports/FormPage.vue")
const ImportShowPage = () => import(/* webpackChunkName: "bo-group-import" */"@/pages/backoffice/imports/ShowPage.vue")
const ImportIndexPage = () => import(/* webpackChunkName: "bo-group-import" */"@/pages/backoffice/imports/IndexPage.vue")

/**
 * Country
 * ------------------------------------------------------------------------------------------------ */
const CountryIndexPage = () => import(/* webpackChunkName: "bo-group-country" */"@/pages/backoffice/countries/IndexPage.vue")
const CountryFormPage = () => import(/* webpackChunkName: "bo-group-country" */"@/pages/backoffice/countries/FormPage.vue")

/**
 * State
 * ------------------------------------------------------------------------------------------------ */
const StateIndexPage = () => import(/* webpackChunkName: "bo-group-state" */"@/pages/backoffice/states/IndexPage.vue")
const StateFormPage = () => import(/* webpackChunkName: "bo-group-state" */"@/pages/backoffice/states/FormPage.vue")

/**
 * Currency
 * ------------------------------------------------------------------------------------------------ */
const CurrencyIndexPage = () => import(/* webpackChunkName: "bo-group-currency" */"@/pages/backoffice/currencies/IndexPage.vue")
const CurrencyFormPage = () => import(/* webpackChunkName: "bo-group-currency" */"@/pages/backoffice/currencies/FormPage.vue")

/**
 * Language
 * ------------------------------------------------------------------------------------------------ */
const LanguageIndexPage = () => import(/* webpackChunkName: "bo-group-language" */"@/pages/backoffice/languages/IndexPage.vue")
const LanguageFormPage = () => import(/* webpackChunkName: "bo-group-language" */"@/pages/backoffice/languages/FormPage.vue")

/**
 * Package
 * ------------------------------------------------------------------------------------------------ */
const PackageIndexPage = () => import(/* webpackChunkName: "bo-group-package" */"@/pages/backoffice/packages/IndexPage.vue")
const PackageFormPage = () => import(/* webpackChunkName: "bo-group-package" */"@/pages/backoffice/packages/FormPage.vue")

/**
 * Inventory
 * ------------------------------------------------------------------------------------------------ */
const InventoryIndexPage = () => import(/* webpackChunkName: "bo-group-inventory" */"@/pages/backoffice/inventories/IndexPage.vue")
const InventoryShowPage = () => import(/* webpackChunkName: "bo-group-inventory" */"@/pages/backoffice/inventories/ShowPage.vue")
const InventoryFormPage = () => import(/* webpackChunkName: "bo-group-inventory" */"@/pages/backoffice/inventories/FormPage.vue")
const InventoryFormImportPage = () => import(/* webpackChunkName: "bo-group-inventory" */"@/pages/backoffice/inventories/FormImportPage.vue")

/**
 * Stock
 * ------------------------------------------------------------------------------------------------ */
const StockIndexPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/IndexPage.vue")
const StockShowPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/ShowPage.vue")
const StockFormPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/FormPage.vue")
const StockItemFormPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/FormStockItemPage.vue")
const StockItemShowPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/ShowStockItemPage.vue")
const StockFormImportPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/FormImportPage.vue")
const StockTransactionIndexPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/ListTransactionsPage.vue")
const StockTransactionFormPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/FormStockTransactionPage.vue")
const StockTransactionShowPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/ShowStockTransactionPage.vue")

const StockPreAlertFormPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/FormPreAlertPage.vue")
const StockPreAlertShowPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/ShowPreAlertPage.vue")
const StockPreAlertItemFormPage = () => import(/* webpackChunkName: "bo-group-stock" */"@/pages/backoffice/stock/FormPreAlertItemPage.vue")


/**
 * Orders
 * ------------------------------------------------------------------------------------------------ */
const OrderIndexPage = () => import(/* webpackChunkName: "bo-group-order" */"@/pages/backoffice/orders/IndexPage.vue")
const OrderFormPage = () => import(/* webpackChunkName: "bo-group-order" */"@/pages/backoffice/orders/FormPage.vue")

const OrderShowPage = () => import(/* webpackChunkName: "bo-group-order" */"@/pages/backoffice/orders/ShowPage.vue")

/**
 * Tracking Events
 * ------------------------------------------------------------------------------------------------ */

const TrackingEventIndexPage = () => import(/* webpackChunkName: "bo-group-tracking" */"@/pages/backoffice/tracking_events/IndexPage.vue")
const TrackingEventFormPage = () => import(/* webpackChunkName: "bo-group-tracking" */"@/pages/backoffice/tracking_events/FormPage.vue")

/**
 * Bank
 * ------------------------------------------------------------------------------------------------ */
const BankIndexPage = () => import(/* webpackChunkName: "bo-group-bank" */"@/pages/backoffice/banks/IndexPage.vue")
const BankFormPage = () => import(/* webpackChunkName: "bo-group-bank" */"@/pages/backoffice/banks/FormPage.vue")


/**
 * Manifest
 * ------------------------------------------------------------------------------------------------ */
const ManifestIndexPage = () => import(/* webpackChunkName: "bo-group-manifest" */"@/pages/backoffice/manifests/IndexPage.vue")
const ManifestFormPage = () => import(/* webpackChunkName: "bo-group-manifest" */"@/pages/backoffice/manifests/FormPage.vue")
const ManifestShowPage = () => import(/* webpackChunkName: "bo-group-manifest" */"@/pages/backoffice/manifests/ShowPage.vue")

/**
 * Masters
 * ------------------------------------------------------------------------------------------------ */
const MasterIndexPage = () => import(/* webpackChunkName: "bo-group-master" */"@/pages/backoffice/masters/IndexPage.vue")
const MasterFormPage = () => import(/* webpackChunkName: "bo-group-master" */"@/pages/backoffice/masters/FormPage.vue")
const MasterShowPage = () => import(/* webpackChunkName: "bo-group-master" */"@/pages/backoffice/masters/ShowPage.vue")

/**
 * Carrier
 * ------------------------------------------------------------------------------------------------ */
const CarrierIndexPage = () => import(/* webpackChunkName: "bo-group-carrier" */"@/pages/backoffice/carriers/IndexPage.vue")
const CarrierFormPage = () => import(/* webpackChunkName: "bo-group-carrier" */"@/pages/backoffice/carriers/FormPage.vue")

/**
 * Departments
 * ------------------------------------------------------------------------------------------------ */
const DepartmentIndexPage = () => import(/* webpackChunkName: "bo-group-department" */"@/pages/backoffice/departments/IndexPage.vue")
const DepartmentFormPage = () => import(/* webpackChunkName: "bo-group-department" */"@/pages/backoffice/departments/FormPage.vue")
const DepartmentShowPage = () => import(/* webpackChunkName: "bo-group-department" */"@/pages/backoffice/departments/ShowPage.vue")

/**
 * SupportSubjects
 * ------------------------------------------------------------------------------------------------ */
const SupportSubjectIndexPage = () => import(/* webpackChunkName: "bo-group-department" */"@/pages/backoffice/support_subjects/IndexPage.vue")
const SupportSubjectFormPage = () => import(/* webpackChunkName: "bo-group-department" */"@/pages/backoffice/support_subjects/FormPage.vue")
const SupportSubjectShowPage = () => import(/* webpackChunkName: "bo-group-department" */"@/pages/backoffice/support_subjects/ShowPage.vue")

/**
 * User
 * ------------------------------------------------------------------------------------------------ */
const UserIndexPage = () => import(/* webpackChunkName: "bo-group-user" */"@/pages/backoffice/users/IndexPage.vue")
const UserShowPage = () => import(/* webpackChunkName: "bo-group-user" */"@/pages/backoffice/users/ShowPage.vue")
const UserFormPage = () => import(/* webpackChunkName: "bo-group-user" */"@/pages/backoffice/users/FormPage.vue")

/**
 * PriceTable
 * ------------------------------------------------------------------------------------------------ */
const PriceTableIndexPage = () => import(/* webpackChunkName: "bo-group-user" */"@/pages/backoffice/price_tables/IndexPage.vue")
const PriceTableShowPage = () => import(/* webpackChunkName: "bo-group-user" */"@/pages/backoffice/price_tables/ShowPage.vue")
const PriceTableFormPage = () => import(/* webpackChunkName: "bo-group-user" */"@/pages/backoffice/price_tables/FormPage.vue")

/**
 * Others
 * ------------------------------------------------------------------------------------------------ */
const NotificationsPage = () => import("@/pages/shared/notifications/IndexPage.vue")
const ReportShipmentPage = () => import(/* webpackChunkName: "bo-reports" */"@/pages/backoffice/reports/ReportShipmentPage.vue")
const ReportDetailedShipmentPage = () => import(/* webpackChunkName: "bo-reports" */"@/pages/backoffice/reports/ReportDetailedShipmentPage.vue")
const ReportStockPage = () => import(/* webpackChunkName: "bo-reports" */"@/pages/backoffice/reports/ReportStockPage.vue")
const ReportFinancialShipmentPage = () => import(/* webpackChunkName: "bo-reports" */"@/pages/backoffice/reports/ReportFinancialShipmentPage.vue")
const InsuranceShipmentReportPage = () => import(/* webpackChunkName: "bo-reports" */"@/pages/backoffice/reports/InsuranceShipmentReportPage.vue")
const ProcessIndexPage = () => import("@/pages/shared/process/IndexPage.vue")

/**
 * Invoices
 * ------------------------------------------------------------------------------------------------ */
const InvoiceIndexPage = () => import(/* webpackChunkName: "bo-group-invoices" */"@/pages/backoffice/invoices/IndexPage.vue")
const InvoiceFormPage = () => import(/* webpackChunkName: "bo-group-invoices" */"@/pages/backoffice/invoices/FormPage.vue")
const InvoiceShowPage = () => import(/* webpackChunkName: "bo-group-invoices" */"@/pages/backoffice/invoices/ShowPage.vue")

/**
 * Tax Tables
 * ------------------------------------------------------------------------------------------------ */

const TaxTablesIndexPage = () => import(/* webpackChunkName: "bo-group-tax_tables" */"@/pages/backoffice/tax_tables/IndexPage.vue")
const TaxTablesFormPage = () => import(/* webpackChunkName: "bo-group-tax_tables" */"@/pages/backoffice/tax_tables/FormPage.vue")
const TaxTablesShowPage = () => import(/* webpackChunkName: "bo-group-tax_tables" */"@/pages/backoffice/tax_tables/ShowPage.vue")

/**
 * Receive
 * ------------------------------------------------------------------------------------------------ */
const ReceiveIndexPage = () => import(/* webpackChunkName: "bo-receive" */"@/pages/backoffice/receive/IndexPage.vue")

/**
 * Integrations
 * ------------------------------------------------------------------------------------------------ */
const IntegrationIndexPage = () => import(/* webpackChunkName: "bo-group-integration" */"@/pages/backoffice/integrations/IndexPage.vue")
const IntegrationShowPage = () => import(/* webpackChunkName: "bo-group-integration" */"@/pages/backoffice/integrations/ShowPage.vue")
const IntegrationTokenFormPage = () => import(/* webpackChunkName: "bo-group-integration" */"@/pages/backoffice/integrations/TokenFormPage.vue")
const IntegrationWebhookFormPage = () => import(/* webpackChunkName: "bo-group-integration" */"@/pages/backoffice/integrations/WebhookFormPage.vue")
// const IntegrationIndexPage = () => import(/* webpackChunkName: "bo-group-integration" */"@/pages/shared/integrations/ViewPage.vue")

// const AnvisaSolicitaIndex = () => import(/* webpackChunkName: "bo-anvisa-solicita" */"@/pages/backoffice/anvisa_solicita/IndexPage.vue")
const BotSolicitaIndex = () => import(/* webpackChunkName: "bo-bot-solicita" */"@/pages/backoffice/bot_solicita/IndexPage.vue")
const BotSolicitaShow = () => import(/* webpackChunkName: "bo-bot-solicita" */"@/pages/backoffice/bot_solicita/ShowPage.vue")

const AnvisaSolicitaIndex = () => import(/* webpackChunkName: "bo-bot-solicita" */"@/pages/backoffice/anvisa_solicita/IndexPage.vue")
const AnvisaSolicitaShow = () => import(/* webpackChunkName: "bo-bot-solicita" */"@/pages/backoffice/anvisa_solicita/IndexPage.vue")

const TestPage = () => import("@/pages/backoffice/test/TestPage.vue")
const MaintananceFormPage = () => import("@/pages/backoffice/maintenance/FormPage.vue")

const ChatPage = () => import("@/pages/shared/chat/ChatPage.vue")

const ToolBulkEventsPage = () => import("@/pages/backoffice/tools/BulkEventPage.vue")
const ToolTrackingPage = () => import("@/pages/backoffice/tools/TrackingPage.vue")
const ToolPreAlertLastMilePage = () => import("@/pages/backoffice/tools/PreAlertLastMilePage.vue")
const RateDiscrepancyReportPage = () => import("@/pages/backoffice/tools/RateDiscrepancyReportPage.vue")
const MawbEventCheckReportPage = () => import("@/pages/backoffice/tools/MawbEventCheckReportPage.vue")

/**
 * SHARED
 * ------------------------------------ */
import AccountFormPage from "@/pages/shared/account/FormPage.vue"
import SupportIndexPage from "@/pages/shared/support/IndexPage.vue"
import SupportFormPage from "@/pages/shared/support/FormPage.vue"
import SupportTicketShowPage from "@/pages/shared/support/ShowPage.vue"

const routes = [
	{
		path: "/",
		component: Dashboard,
		children: [
			{
				path: "/chats",
				component: ChatPage,
				name: "chats",
				props: true
			},
			{
				path: "/",
				component: DashboardPage,
				props: true
			},
			{
				path: "/dashboard",
				component: DashboardPage,
				name: "dashboard",
				props: true
			},
			/**
			 * Subsidiaries
			 * ------------------------------------ */
			{
				path: "/subsidiaries",
				component: SubsidiariesIndexPage,
				name: "subsidiaries.index",
				props: true
			},
			{
				path: "/subsidiaries/create",
				component: SubsidiariesFormPage,
				name: "subsidiaries.create",
				props: true
			},
			{
				path: "/subsidiaries/:id",
				component: SubsidiariesFormPage,
				name: "subsidiaries.show",
				props: true
			},
			/**
			 * Services
			 * ------------------------------------ */
			{
				path: "/services",
				component: ServiceIndexPage,
				name: "services.index",
				props: true
			},
			{
				path: "/services/create",
				component: ServiceFormPage,
				name: "services.create",
				props: true
			},
			{
				path: "/services/:id/edit",
				component: ServiceFormPage,
				name: "services.edit",
				props: true
			},
			{
				path: "/services/:id",
				component: ServiceFormPage,
				name: "services.show",
				props: true
			},
			/**
			 * Service Groups
			 * ------------------------------------ */
			{
				path: "/service_groups",
				component: ServiceGroupIndexPage,
				name: "service_groups.index",
				props: true
			},
			{
				path: "/service_groups/create",
				component: ServiceGroupFormPage,
				name: "service_groups.create",
				props: true
			},
			{
				path: "/service_groups/:id",
				component: ServiceGroupFormPage,
				name: "service_groups.edit",
				props: true
			},
			/**
			 * Partner
			 * ------------------------------------ */
			{
				path: "/partners",
				component: PartnerIndexPage,
				name: "partners.index",
				props: true
			},
			{
				path: "/partners/create",
				component: PartnerFormPage,
				name: "partners.create",
				props: true
			},
			{
				path: "/partners/:id/edit",
				component: PartnerFormPage,
				name: "partners.edit",
				props: true
			},
			{
				path: "/partners/:id",
				component: PartnerFormPage,
				name: "partners.show",
				props: true
			},
			/**
			 * Shipments
			 * ------------------------------------ */
			{
				path: "/shipments",
				component: ShipmentIndexPage,
				name: "shipments.index",
				props: router => ({ category: router.query.category })
			},
			{
				path: "/shipments/create",
				component: ShipmentFormPage,
				name: "shipments.create",
				props: true
			},
			{
				path: "/shipments/create-cte",
				component: ShipmentFormCTEPage,
				name: "shipments.create-cte",
				props: true
			},
			{
				path: "/shipments/:id/edit",
				component: ShipmentFormPage,
				name: "shipments.edit",
				props: true
			},
			{
				path: "/shipments/:id",
				component: ShipmentShowPage,
				name: "shipments.show",
				props: true
			},
			{
				path: "/shipments-analyze",
				component: ShipmentAnalyzePage,
				name: "shipments.analyze",
				props: true
			},
			/**
			 * Orders
			 * ------------------------------------ */
			{
				path: "/orders",
				component: OrderIndexPage,
				name: "orders.index",
				props: true
			},
			{
				path: "/orders/create",
				component: OrderFormPage,
				name: "orders.create",
				props: true
			},
			{
				path: "/orders/:id/edit",
				component: OrderFormPage,
				name: "orders.edit",
				props: true
			},
			{
				path: "/orders/:id",
				component: OrderShowPage,
				name: "orders.show",
				props: true
			},
			/**
			 * Manifests
			 * ------------------------------------ */
			{
				path: "/manifests",
				component: ManifestIndexPage,
				name: "manifests.index",
				props: true
			},
			{
				path: "/manifests/create",
				component: ManifestFormPage,
				name: "manifests.create",
				props: true
			},
			{
				path: "/manifests/:id/edit",
				component: ManifestFormPage,
				name: "manifests.edit",
				props: true
			},
			{
				path: "/manifests/:id",
				component: ManifestShowPage,
				name: "manifests.show",
				props: true
			},
			/**
			 * Masters
			 * ------------------------------------ */
			{
				path: "/masters",
				component: MasterIndexPage,
				name: "masters.index",
				props: true
			},
			{
				path: "/masters/create",
				component: MasterFormPage,
				name: "masters.create",
				props: true
			},
			{
				path: "/masters/:id/edit",
				component: MasterFormPage,
				name: "masters.edit",
				props: true
			},
			{
				path: "/masters/:id",
				component: MasterShowPage,
				name: "masters.show",
				props: true
			},
			/**
			 * Client
			 * ------------------------------------ */
			{
				path: "/clients",
				component: ClientIndexPage,
				name: "clients.index",
				props: true
			},
			{
				path: "/clients/create",
				component: ClientFormPage,
				name: "clients.create",
				props: true
			},
			{
				path: "/clients/:id",
				component: ClientFormPage,
				name: "client.edit",
				props: true
			},
			/**
			 * Provider
			 * ------------------------------------ */
			{
				path: "/providers",
				component: ProviderIndexPage,
				name: "providers.index",
				props: true
			},
			{
				path: "/providers/create",
				component: ProviderFormPage,
				name: "providers.create",
				props: true
			},
			{
				path: "/providers/:id/edit",
				component: ProviderFormPage,
				name: "providers.edit",
				props: true
			},
			{
				path: "/providers/:id",
				component: ProviderFormPage,
				name: "providers.show",
				props: true
			},
			/**
			 * Warehouse
			 * ------------------------------------ */
			{
				path: "/warehouses",
				component: WarehouseIndexPage,
				name: "warehouses.index",
				props: true
			},
			{
				path: "/warehouses/create",
				component: WarehouseFormPage,
				name: "warehouses.create",
				props: true
			},
			{
				path: "/warehouses/:id",
				component: WarehouseFormPage,
				name: "warehouses.show",
				props: true
			},
			/**
			 * Inventory
			 * ------------------------------------ */
			{
				path: "/inventories",
				component: InventoryIndexPage,
				name: "inventories.index",
				props: true
			},
			{
				path: "/inventories/create",
				component: InventoryFormPage,
				name: "inventories.create",
				props: true
			},
			{
				path: "/inventories/import",
				component: InventoryFormImportPage,
				name: "inventories.import",
				props: true
			},
			{
				path: "/inventories/:id/edit",
				component: InventoryFormPage,
				name: "inventories.edit",
				props: true
			},
			{
				path: "/inventories/:id",
				component: InventoryShowPage,
				name: "inventories.show",
				props: true
			},
			/**
			 * Stock
			 * ------------------------------------ */
			{
				path: "/stocks",
				component: StockIndexPage,
				name: "stocks.index",
				props: true
			},
			{
				path: "/stocks/create",
				component: StockFormPage,
				name: "stocks.create",
				props: true
			},
			{
				path: "/stocks/import",
				component: StockFormImportPage,
				name: "stocks.import",
				props: true
			},
			{
				path: "/stocks/:id",
				component: StockShowPage,
				name: "stocks.show",
				props: true
			},
			{
				path: "/stocks/:id/edit",
				component: StockFormPage,
				name: "stocks.edit",
				props: true
			},
			{
				path: "/stock_items/:id/edit",
				component: StockItemFormPage,
				name: "stock_items.edit",
				props: true
			},
			{
				path: "/stock_items/create",
				component: StockItemFormPage,
				name: "stock_items.create",
				props: true
			},
			{
				path: "/stock_items/:id",
				component: StockItemShowPage,
				name: "stock_items.show",
				props: true
			},
			{
				path: "/stock_transactions/create",
				component: StockTransactionFormPage,
				name: "stock_transactions.create",
				props: true
			},
			{
				path: "/stock_transactions/:id",
				component: StockTransactionShowPage,
				name: "stock_transactions.show",
				props: true
			},
			{
				path: "/stock/:id/pre_alerts",
				component: StockTransactionShowPage,
				name: "stock_pre_alerts.index",
				props: true
			},
			/**
			 * Stock Pre Alerts
			 * ------------------------------------ */
			{
				path: "/stock_pre_alerts/create",
				component: StockPreAlertFormPage,
				name: "stock_pre_alerts.create",
				props: true
			},
			{
				path: "/stock_pre_alerts/:id/edit",
				component: StockPreAlertFormPage,
				name: "stock_pre_alerts.edit",
				props: true
			},
			{
				path: "/stock_pre_alerts/:id",
				component: StockPreAlertShowPage,
				name: "stock_pre_alerts.show",
				props: true
			},
			{
				path: "/stock_pre_alert_items/create",
				component: StockPreAlertItemFormPage,
				name: "stock_pre_alert_items.create",
				props: true
			},
			{
				path: "/stock_pre_alert_items/:id/edit",
				component: StockPreAlertItemFormPage,
				name: "stock_pre_alert_items.edit",
				props: true
			},
			/**
			 * Invoices
			 * ------------------------------------ */
			{
				path: "/invoices",
				component: InvoiceIndexPage,
				name: "invoices.index",
				props: true
			},
			{
				path: "/invoices/create",
				component: InvoiceFormPage,
				name: "invoices.create",
				props: true
			},
			{
				path: "/invoices/:id/edit",
				component: InvoiceFormPage,
				name: "invoices.edit",
				props: true
			},
			{
				path: "/invoices/:id",
				component: InvoiceShowPage,
				name: "invoices.show",
				props: true
			},
			/**
			 * Stock Transactions
			 * ------------------------------------ */
			{
				path: "/stock-transactions",
				component: StockTransactionIndexPage,
				name: "stock-transactions",
				props: true
			},
			/**
			 * Users
			 * ------------------------------------ */
			{
				path: "/users",
				component: UserIndexPage,
				name: "users.index",
				props: true
			},
			{
				path: "/users/create",
				component: UserFormPage,
				name: "users.create",
				props: true
			},
			{
				path: "/users/:id/edit",
				component: UserFormPage,
				name: "users.edit",
				props: true
			},
			{
				path: "/users/:id",
				component: UserShowPage,
				name: "users.show",
				props: true
			},
			/**
			 * My Account
			 * ------------------------------------ */
			{
				path: "/my-account",
				component: AccountFormPage,
				name: "my-account",
				props: true
			},
			/**
			 * INTEGRATIONS
			 * ------------------------------------ */
			{
				path: "/integrations",
				component: IntegrationIndexPage,
				name: "integrations.index",
				props: true
			},
			{
				path: "/integrations/webhooks/create",
				component: IntegrationWebhookFormPage,
				name: "webhooks.create",
				props: true
			},
			{
				path: "/integrations/webhooks/:id/edit",
				component: IntegrationWebhookFormPage,
				name: "webhooks.edit",
				props: true
			},
			{
				path: "/integrations/tokens/create",
				component: IntegrationTokenFormPage,
				name: "integrations.tokens.create",
				props: true
			},
			{
				path: "/integrations/tokens/:id/edit",
				component: IntegrationTokenFormPage,
				name: "integrations.tokens.edit",
				props: true
			},
			/**
			 * Countries
			 * ------------------------------------ */
			{
				path: "/countries",
				component: CountryIndexPage,
				name: "countries.index",
				props: true
			},
			{
				path: "/countries/create",
				component: CountryFormPage,
				name: "countries.create",
				props: true
			},
			{
				path: "/countries/:id",
				component: CountryFormPage,
				name: "countries.edit",
				props: true
			},
			{
				path: "/countries/:id",
				component: CountryFormPage,
				name: "countries.show",
				props: true
			},
			/**
			 * States
			 * ------------------------------------ */
			{
				path: "/states",
				component: StateIndexPage,
				name: "states.index",
				props: true
			},
			{
				path: "/states/create",
				component: StateFormPage,
				name: "states.create",
				props: true
			},
			{
				path: "/states/:id",
				component: StateFormPage,
				name: "states.show",
				props: true
			},
			/**
			 * Airports
			 * ------------------------------------ */
			{
				path: "/airports",
				component: AirportIndexPage,
				name: "airports.index",
				props: true
			},
			{
				path: "/airports/create",
				component: AirportFormPage,
				name: "airports.create",
				props: true
			},
			{
				path: "/airports/:id",
				component: AirportFormPage,
				name: "airport.edit",
				props: true
			},
			/**
			 * Auxiliar - Currencies
			 * ------------------------------------ */
			{
				path: "/currencies",
				component: CurrencyIndexPage,
				name: "currencies.index",
				props: true
			},
			{
				path: "/currencies/create",
				component: CurrencyFormPage,
				name: "currencies.create",
				props: true
			},
			{
				path: "/currencies/:id/edit",
				component: CurrencyFormPage,
				name: "currencies.edit",
				props: true
			},
			{
				path: "/currencies/:id",
				component: CurrencyFormPage,
				name: "currencies.show",
				props: true
			},
			/**
			 * Auxiliar - Languages
			 * ------------------------------------ */
			{
				path: "/languages",
				component: LanguageIndexPage,
				name: "languages.index",
				props: true
			},
			{
				path: "/languages/create",
				component: LanguageFormPage,
				name: "languages.create",
				props: true
			},
			{
				path: "/languages/:id/edit",
				component: LanguageFormPage,
				name: "languages.edit",
				props: true
			},
			{
				path: "/languages/:id",
				component: LanguageFormPage,
				name: "languages.show",
				props: true
			},
			/**
			 * Packages
			 * ------------------------------------ */
			{
				path: "/packages",
				component: PackageIndexPage,
				name: "packages.index",
				props: true
			},
			{
				path: "/packages/create",
				component: PackageFormPage,
				name: "packages.create",
				props: true
			},
			{
				path: "/packages/:id/edit",
				component: PackageFormPage,
				name: "packages.edit",
				props: true
			},
			{
				path: "/packages/:id",
				component: PackageFormPage,
				name: "packages.show",
				props: true
			},
			/**
			 * Shipment Events
			 * ------------------------------------ */
			{
				path: "/tracking-events",
				component: TrackingEventIndexPage,
				name: "tracking_events.index",
				props: true
			},
			{
				path: "/tracking-events/create",
				component: TrackingEventFormPage,
				name: "tracking_events.create",
				props: true
			},
			{
				path: "/tracking-events/:id",
				component: TrackingEventFormPage,
				name: "tracking_events.show",
				props: true
			},
			{
				path: "/tracking-events/:id/edit",
				component: TrackingEventFormPage,
				name: "tracking_events.edit",
				props: true
			},
			/**
			 * Bank
			 * ------------------------------------ */
			{
				path: "/banks",
				component: BankIndexPage,
				name: "banks.index",
				props: true
			},
			{
				path: "/banks/create",
				component: BankFormPage,
				name: "banks.create",
				props: true
			},
			{
				path: "/banks/:id/edit",
				component: BankFormPage,
				name: "banks.edit",
				props: true
			},
			/**
			 * Carriers
			 * ------------------------------------ */
			{
				path: "/carriers",
				component: CarrierIndexPage,
				name: "carriers.index",
				props: true
			},
			{
				path: "/carriers/create",
				component: CarrierFormPage,
				name: "carriers.create",
				props: true
			},
			{
				path: "/carriers/:id/edit",
				component: CarrierFormPage,
				name: "carriers.edit",
				props: true
			},
			{
				path: "/carriers/:id",
				component: CarrierFormPage,
				name: "carriers.show",
				props: true
			},
			/**
			 * Departments
			 * ------------------------------------ */
			{
				path: "/departments",
				component: DepartmentIndexPage,
				name: "departments.index",
				props: true
			},
			{
				path: "/departments/create",
				component: DepartmentFormPage,
				name: "departments.create",
				props: true
			},
			{
				path: "/departments/:id/edit",
				component: DepartmentFormPage,
				name: "departments.edit",
				props: true
			},
			{
				path: "/departments/:id",
				component: DepartmentShowPage,
				name: "departments.show",
				props: true
			},
			/**
			 * SupportSubjects
			 * ------------------------------------ */
			{
				path: "/support-subjects",
				component: SupportSubjectIndexPage,
				name: "support-subjects.index",
				props: true
			},
			{
				path: "/support-subjects/create",
				component: SupportSubjectFormPage,
				name: "support-subjects.create",
				props: true
			},
			{
				path: "/support-subjects/:id/edit",
				component: SupportSubjectFormPage,
				name: "support-subjects.edit",
				props: true
			},
			{
				path: "/support-subjects/:id",
				component: SupportSubjectShowPage,
				name: "support-subjects.show",
				props: true
			},
			/**
			 * Documents
			 * ------------------------------------ */
			{
				path: "/documents",
				component: DocumentIndexPage,
				name: "documents.index",
				props: true
			},
			{
				path: "/documents/create",
				component: DocumentFormPage,
				name: "documents.create",
				props: true
			},
			{
				path: "/documents/:id/edit",
				component: DocumentFormPage,
				name: "documents.edit",
				props: true
			},
			{
				path: "/documents/:id",
				component: DocumentShowPage,
				name: "documents.show",
				props: true
			},
			/**
			 * Imports
			 * ------------------------------------ */
			{
				path: "/imports",
				component: ImportIndexPage,
				name: "imports"
			},
			{
				path: "/imports",
				component: ImportIndexPage,
				name: "imports.index"
			},
			{
				path: "/imports/shipments",
				component: ImportSelectPage,
				name: "imports-shipments"
			},
			{
				path: "/imports/price-tables",
				component: ImportSelectPage,
				name: "imports-price-tables"
			},
			{
				path: "/imports/select",
				component: ImportSelectPage,
				name: "import-select",
				props: true
			},
			{
				path: "/imports/create",
				component: ImportFormPage,
				name: "import-create",
				props: true
			},
			{
				path: "/imports/create",
				component: ImportFormPage,
				name: "imports.create",
				props: true
			},
			{
				path: "/imports/:id",
				component: ImportShowPage,
				name: "imports.show",
				props: true
			},
			/**
			 * Review
			 * ------------------------------------ */
			{
				path: "/receive",
				component: ReceiveIndexPage,
				name: "receive",
				props: true
			},
			/**
			 * Review
			 * ------------------------------------ */
			{
				path: "/reviews",
				component: ReviewIndexPage,
				name: "reviews",
				props: true
			},
			{
				path: "/reviews/create",
				component: ReviewFormPage,
				name: "review-create",
				props: true
			},
			{
				path: "/reviews/:id",
				component: ReviewFormPage,
				name: "review",
				props: true
			},
			/**
			 * Support Tickets
			 * ------------------------------------ */
			{
				path: "/supports",
				component: SupportIndexPage,
				name: "supports.index",
				props: true
			},
			{
				path: "/supports/create",
				component: SupportFormPage,
				name: "supports.create",
				props: true
			},
			{
				path: "/support_tickets/:id",
				component: SupportTicketShowPage,
				name: "support_tickets.show",
				props: true
			},
			{
				path: "/supports/:id",
				component: SupportFormPage,
				name: "supports.show",
				props: true
			},
			/**
			 * Reports
			 * ------------------------------------ */
			{
				path: "/reports/shipments",
				component: ReportShipmentPage,
				name: "reports.shipments",
				props: true
			},
			{
				path: "/reports/detailed_shipments",
				component: ReportDetailedShipmentPage,
				name: "reports.detailed_shipments",
				props: true
			},
			{
				path: "/reports/stocks",
				component: ReportStockPage,
				name: "reports.stocks",
				props: true
			},
			{
				path: "/reports/financial-shipments",
				component: ReportFinancialShipmentPage,
				name: "reports.financial_shipments",
				props: true
			},
			{
				path: "/reports/insurance-shipments",
				component: InsuranceShipmentReportPage,
				name: "reports.insurance_shipments",
				props: true
			},
			/**
			 * PriceTables
			 * ------------------------------------ */
			{
				path: "/price_tables",
				component: PriceTableIndexPage,
				name: "price_tables.index",
				props: true
			},
			{
				path: "/price_tables/create",
				component: PriceTableFormPage,
				name: "price_tables.create",
				props: true
			},
			{
				path: "/price_tables/:id/edit",
				component: PriceTableFormPage,
				name: "price_tables.edit",
				props: true
			},
			{
				path: "/price_tables/:id",
				component: PriceTableShowPage,
				name: "price_tables.show",
				props: true
			},
			/**
			 * TaxTables
			 * ------------------------------------ */
			{
				path: "/tax_tables",
				component: TaxTablesIndexPage,
				name: "tax_tables.index",
				props: true
			},
			{
				path: "/tax_tables/create",
				component: TaxTablesFormPage,
				name: "tax_tables.create",
				props: true
			},
			{
				path: "/tax_tables/:id/edit",
				component: TaxTablesFormPage,
				name: "tax_tables.edit",
				props: true
			},
			{
				path: "/tax_tables/:id",
				component: TaxTablesShowPage,
				name: "tax_tables.show",
				props: true
			},
			/**
			 * ReceitaFederal
			 * ------------------------------------ */
			{
				path: "/supervisory_organ",
				component: PriceTableShowPage,
				name: "supervisory_organ.show",
				props: true
			},
			/**
			 * Geral
			 * ------------------------------------ */
			{
				path: "/notifications",
				component: NotificationsPage,
				name: "notifications"
			},
			{
				path: "/processes",
				component: ProcessIndexPage,
				name: "processes"
			},
			/**
			 * BOT SOLICITA
			 */
			{
				path: "/bot_solicita",
				component: BotSolicitaIndex,
				name: "bot_solicita.index"
			},
			{
				path: "/bot_solicita/:id",
				component: BotSolicitaShow,
				name: "bot_solicita.show"
			},
			/**
			 * TOOLS
			 */
			{
				path: "/tools/bulk_events",
				component: ToolBulkEventsPage,
				name: "tools.bulk_events"
			},
			{
				path: "/tools/tracking",
				component: ToolTrackingPage,
				name: "tools.tracking"
			},
			{
				path: "/tools/pre_alert_lastmile",
				component: ToolPreAlertLastMilePage,
				name: "tools.pre_alert_lastmile"
			},
			{
				path: "/tools/rate_discrepancy_report",
				component: RateDiscrepancyReportPage,
				name: "tools.rate_discrepancy_report"
			},
			{
				path: "/tools/mawb_event_check_report",
				component: MawbEventCheckReportPage,
				name: "tools.mawb_event_check_report"
			},

			/**
			 * ANVISA SOLICITA
			 */
			{
				path: "/anvisa_solicita",
				component: AnvisaSolicitaIndex,
				name: "anvisa_solicita.index"
			},
			{
				path: "/anvisa_solicita/:id",
				component: AnvisaSolicitaShow,
				name: "anvisa_solicita.show"
			},
			/**
			 * Maintanance
			 * ------------------------------------ */
			{
				path: "/maintanance/:entity/:id/edit",
				component: MaintananceFormPage,
				name: "maintanance.edit"
			},
			/**
			 * Test
			 * ------------------------------------ */
			{
				path: "/test",
				component: TestPage,
				name: "test"
			},
			{
				path: "*",
				component: DashboardPage
			}
		]
	}
]

const router = new VueRouter({
	// mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
	mode: "history",
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})

let nProgressTimeout

router.beforeResolve((to, from, next) => {
	if (to.name) {
		if (nProgressTimeout) clearTimeout(nProgressTimeout)
		nProgressTimeout = setTimeout(() => NProgress.start(), 1000)
	}
	next()
})

router.afterEach(() => {
	if (nProgressTimeout) clearTimeout(nProgressTimeout)
	NProgress.done()
})

/**
 * Filtro carregar o perfil atual
 * ------------------------------------ */
router.beforeEach(async (to, from, next) => {

	if (!store.state.APP.profile?.id) {
		await dispatch("APP/init")
	}

	next()
})

export default router
