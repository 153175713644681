import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"
import {dispatch} from "vuex-pathify"
import NProgress from 'nprogress'

Vue.use(VueRouter)

import Dashboard from "@/layout/partner/Dashboard"
import DashboardPage from "@/pages/partner/dashboard/DashboardPage.vue"

const ShipmentIndexPage = () => import(/* webpackChunkName: "partner-group-shipments" */"@/pages/partner/shipments/IndexPage.vue")
const ShipmentFormPage = () => import(/* webpackChunkName: "partner-group-shipments" */"@/pages/partner/shipments/FormPage.vue")
const ShipmentShowPage = () => import(/* webpackChunkName: "partner-group-shipments" */"@/pages/partner/shipments/ShowPage.vue")

const ManifestIndexPage = () => import(/* webpackChunkName: "partner-group-manifests" */"@/pages/shared/manifests/IndexPage.vue")
const ManifestFormPage = () => import(/* webpackChunkName: "partner-group-manifests" */"@/pages/shared/manifests/FormPage.vue")
const ManifestViewPage = () => import(/* webpackChunkName: "partner-group-manifests" */"@/pages/shared/manifests/ViewPage.vue")

const AccountPage = () => import(/* webpackChunkName: "partner-group-account" */"@/pages/shared/account/FormPage.vue")

const IntegrationIndexPage = () => import(/* webpackChunkName: "partner-group-integrations" */"@/pages/shared/integrations/ViewPage.vue")

const ServiceIndexPage = () => import(/* webpackChunkName: "partner-group-service" */"@/pages/partner/services/IndexPage.vue")
const ServiceFormPage = () => import(/* webpackChunkName: "partner-group-service" */"@/pages/partner/services/FormPage.vue")

const SupportIndexPage = () => import(/* webpackChunkName: "partner-group-support" */"@/pages/shared/support/IndexPage.vue")
const SupportFormPage = () => import(/* webpackChunkName: "partner-group-support" */"@/pages/shared/support/FormPage.vue")

const ImportSelectPage = () => import(/* webpackChunkName: "partner-group-import" */"@/pages/partner/import/SelectPage.vue")
const ImportFormPage = () => import(/* webpackChunkName: "partner-group-import" */"@/pages/partner/import/FormPage.vue")
const ImportIndexPage = () => import(/* webpackChunkName: "partner-group-import" */"@/pages/partner/import/IndexPage.vue")

const StockIndexPage = () => import(/* webpackChunkName: "partner-group-stock" */"@/pages/partner/stock/IndexPage.vue")
const StockViewPage = () => import(/* webpackChunkName: "partner-group-stock" */"@/pages/partner/stock/ViewPage.vue")
const StockItemFormPage = () => import(/* webpackChunkName: "partner-group-stock" */"@/pages/partner/stock/FormStockItemPage.vue")
const StockFormPage = () => import(/* webpackChunkName: "partner-group-stock" */"@/pages/partner/stock/FormPage.vue")
const NotificationPage = () => import("@/pages/shared/notifications/IndexPage.vue")

const TrackingPage = () => import("@/pages/partner/my_packages/TrackingPage.vue")

const ReportShipmentPage = () => import("@/pages/shared/reports/ReportShipmentPage.vue")
const ReportDetailedShipmentPage = () => import("@/pages/shared/reports/ReportDetailedShipmentPage.vue")

const ProcessIndexPage = () => import("@/pages/shared/process/IndexPage.vue")


// import ImportViewPage from "@/pages/backoffice/import/ViewPage"
// import VendaPage from "@/pages/backoffice/vendas/Page"
// import EstoquePage from "@/pages/backoffice/estoque/Page"
// import ProfilePage from "@/pages/backoffice/profile/Page"
// import RelatorioPage from "@/pages/backoffice/relatorios/Page"

const routes = [
    {
        path: "/",
        component: Dashboard,
        children: [
            {
                path: "/",
                component: DashboardPage,
                props: true
            },
            {
                path: "/dashboard",
                component: DashboardPage,
                name: 'dashboard'
            },
            /**
             * My Account
             * ------------------------------------ */
            {
                path: "/my-account",
                component: AccountPage,
                name: 'my-account',
                props: true
            },
            /**
             * Extra
             * ------------------------------------ */
            {
                path: "/notifications",
                component: NotificationPage,
                name: 'notifications'
            },
            {
                path: "/tracking",
                component: TrackingPage,
                name: 'tracking',
                props: true
            },
            /**
             * Services
             * ------------------------------------ */
            {
                path: "/services",
                component: ServiceIndexPage,
                name: 'services',
                props: true
            },
            {
                path: "/services/create",
                component: ServiceFormPage,
                name: 'service-create',
                props: true
            },
            {
                path: "/services/:id",
                component: ServiceFormPage,
                name: 'service',
                props: true
            },
            /**
             * Integrations
             * ------------------------------------ */
            {
                path: "/integrations",
                component: IntegrationIndexPage,
                name: 'integrations',
                props: true
            },
            /**
             * Imports
             * ------------------------------------ */
            {
                path: "/imports",
                component: ImportIndexPage,
                name: 'imports'
            },
            {
                path: "/imports/shipments",
                component: ImportSelectPage,
                name: 'imports-shipments'
            },
            {
                path: "/imports/price-tables",
                component: ImportSelectPage,
                name: 'imports-price-tables'
            },
            {
                path: "/imports/select",
                component: ImportSelectPage,
                name: 'import-select',
                props: true
            },
            {
                path: "/imports/create",
                component: ImportFormPage,
                name: 'import-create',
                props: true
            },
            {
                path: "/imports/:id",
                component: ImportFormPage,
                name: 'import',
                props: true
            },
            /**
             * Support
             * ------------------------------------ */
            {
                path: "/supports",
                component: SupportIndexPage,
                name: 'supports',
                props: true
            },
            {
                path: "/supports/create",
                component: SupportFormPage,
                name: 'support-create',
                props: true
            },
            {
                path: "/supports/:id",
                component: SupportFormPage,
                name: 'support',
                props: true
            },
            /**
             * Shipments
             * ------------------------------------ */
            {
                path: "/shipments",
                component: ShipmentIndexPage,
                name: 'shipments',
                props: true
            },
            {
                path: "/shipments/create",
                component: ShipmentFormPage,
                name: 'shipments.create',
                props: true
            },
            {
                path: "/shipments/create",
                component: ShipmentFormPage,
                name: 'shipment-create',
                props: true
            },
            {
                path: "/shipments/:id/edit",
                component: ShipmentFormPage,
                name: 'shipment-edit',
                props: true
            },
            {
                path: "/shipments/:id/edit",
                component: ShipmentFormPage,
                name: 'shipments.edit',
                props: true
            },
            {
                path: "/shipments/:id",
                component: ShipmentShowPage,
                name: 'shipments.show',
                props: true
            },
            {
                path: "/shipments/:id",
                component: ShipmentShowPage,
                name: 'shipment',
                props: true
            },
            /**
             * Manifests
             * ------------------------------------ */
            {
                path: "/manifests",
                component: ManifestIndexPage,
                name: 'manifests',
                props: true
            },
            {
                path: "/manifests/create",
                component: ManifestFormPage,
                name: 'manifest-create',
                props: true
            },
            {
                path: "/manifests/create",
                component: ManifestFormPage,
                name: 'manifests.create',
                props: true
            },
            {
                path: "/manifests/:id/edit",
                component: ManifestFormPage,
                name: 'manifests.edit',
                props: true
            },
            {
                path: "/manifests/:id/edit",
                component: ManifestFormPage,
                name: 'manifest-edit',
                props: true
            },
            {
                path: "/manifests/:id",
                component: ManifestViewPage,
                name: 'manifest',
                props: true
            },
            {
                path: "/manifests/:id",
                component: ManifestViewPage,
                name: 'manifests.show',
                props: true
            },
            /**
             * Stock
             * ------------------------------------ */
            {
                path: "/stocks",
                component: StockIndexPage,
                name: 'stocks',
                props: true
            },
            {
                path: "/stocks/create",
                component: StockFormPage,
                name: 'stocks-create',
                props: true
            },
            {
                path: "/stocks/:id",
                component: StockViewPage,
                name: 'stock',
                props: true
            },
            {
                path: "/stocks/:id/edit",
                component: StockFormPage,
                name: 'stock-edit',
                props: true
            },
            {
                path: "/stock_items/:id/edit",
                component: StockItemFormPage,
                name: 'stock_items.edit',
                props: true
            },
            /**
             * Reports
             * ------------------------------------ */
            {
                path: "/reports/shipments",
                component: ReportShipmentPage,
                name: 'reports.shipments',
                props: true
            },
            {
                path: "/reports/detailed_shipments",
                component: ReportDetailedShipmentPage,
                name: 'reports.detailed_shipments',
                props: true
            },
            {
                path: "/processes",
                component: ProcessIndexPage,
                name: 'processes'
            },
            {
                path: '*',
                component: DashboardPage
            }
        ]
    }
]

const router = new VueRouter({
    mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

let nProgressTimeout;

router.beforeResolve((to, from, next) => {
    if (to.name) {
        if (nProgressTimeout) clearTimeout(nProgressTimeout)
        console.debug("router.beforeResolve: NProgress start")

        nProgressTimeout = setTimeout(() => {
            console.debug("router.beforeResolve: NProgress start")
            NProgress.start()
        }, 1000)
    }
    next()
})

router.afterEach(() => {
    if (nProgressTimeout) clearTimeout(nProgressTimeout)
    console.debug("router.afterEach: NProgress done")
    NProgress.done()
})

/**
 * Filtro carregar o perfil atual
 * ------------------------------------ */
router.beforeEach(async (to, from, next) => {

    if (!store.state.APP.profile?.id) {
        await dispatch('APP/init')
    }

    next()
})

export default router
