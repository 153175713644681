import "./entry/bootstrap.js"
import Http from "@/config/axios.js"
import Vue from "vue"
import PortalVue from 'portal-vue'

import AuthApp from "@/Auth.vue"
import App from "@/App.vue"

import store from "@/store.js"
import i18n from "@/i18n.js"

import localePlugin from "@/plugins/locale.js"
import domainPlugin from "@/plugins/domain.js"
import numeralPlugin from "@/plugins/numeral.js"
import dataGet from "@/plugins/data_get.js"
import userPlugin from "@/plugins/user.js"
import dayjsPlugin from "@/plugins/dayjs.js"
import tailwindPlugin from "@/plugins/tailwind.js"
import VCalendar from "v-calendar"
import VTooltip from 'v-tooltip'
import VMoney from "v-money"
import VueTheMask from 'vue-the-mask'
import "@/helpers.js"

import User from "@/API/User.js"
import VueRouter from "vue-router"
import { to } from "await-to-js"

Vue.use(VueRouter)
Vue.use(dataGet)
Vue.use(localePlugin)
Vue.use(domainPlugin)
Vue.use(userPlugin)
Vue.use(dayjsPlugin)
Vue.use(numeralPlugin)
Vue.use(VCalendar)
Vue.use(VTooltip)
Vue.use(VueTheMask)
Vue.use(VMoney, {precision: 2})
Vue.use(PortalVue)
Vue.use(tailwindPlugin)

Vue.config.productionTip = false

// const router = authRouter

export default (async () => {

    let [err, res] = await to(Http.get('/sanctum/csrf-cookie'));

    // Http.defaults.headers['x-csrf-token'] = res.data.csrf_token;

    [err, res] = await User.current()

    let APP = null

    if (err) {
        APP = new Vue({
            router: require('@/routes/auth.js').default,
            store,
            i18n,
            render: (h) => h(AuthApp)
        }).$mount("#App")
    } else {

        switch (res.data.data.user_type) {
            case 770:
            case 777:
                window.API_PREFIX = "/backoffice"
                APP = new Vue({
                    router: require('@/routes/backoffice/routes.js').default,
                    store,
                    i18n,
                    render: (h) => h(App)
                }).$mount("#App")
            break
            case 20:
                window.API_PREFIX = "/partner"
                APP = new Vue({
                    router: require('@/routes/partner/routes.js').default,
                    store,
                    i18n,
                    render: (h) => h(App)
                }).$mount("#App")
            break
        }

    }
})()