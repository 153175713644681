import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async current() {
        return to(Http.get(`/user`))
    },
    async list(params) {
        return to(Http.get(window.API_PREFIX + "/users", {params}))
    },
    async get(id) {
        return to(Http.get(window.API_PREFIX + `/users/${id}`))
    },
    async store(data) {
        return to(Http.post(window.API_PREFIX + `/users`, data))
    },
    async update(id, data) {
        return to(Http.post(window.API_PREFIX + `/users/${id}`, {'_method': 'patch', ...data}))
    },
    async save(data, id) {
        return id ? this.update(id, data) : this.store(data)
    },
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/users/${id}`, {'_method': 'delete'}))
    },
    async switchTo(id){
        return to(Http.post(`/backoffice/user-switch/${id}`))
    },
    async switchBack(id){
        return to(Http.post( `/backoffice/user-switch-back`))
    }
}

